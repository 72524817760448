import React, { useState } from 'react';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './contentrequest.style';

const ContentRequest = () => {
  const [formData, setFormData] = useState({
    email: '',
    fullname: '',
    title: '',
    category: 'AI for VC', // Default value
    description: '',
    subscribe: true, // Default checked
  });

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  // Update form data on input change
  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
    // Clear any previous error for the current field
    setErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
  };

  // Validation functions
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return emailRegex.test(email);
  };

  // Submit form data to NoCodeDB
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form's default submit behavior
    setMessage(''); // Clear previous message
    setErrors({}); // Clear previous errors

    // Validate fields
    let formErrors = {};
    if (!formData.fullname) {
      formErrors.fullname = 'Full Name is required.';
    }
    if (!formData.title) {
      formErrors.title = 'Title is required.';
    }
    if (!validateEmail(formData.email)) {
      formErrors.email = 'Please enter a valid email address.';
    }
    if (!formData.description) {
      formErrors.description = 'Please provide details about what you’d like to learn.';
    }

    // If there are errors, set them and return
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await fetch('https://app.nocodb.com/api/v2/tables/mu34uy98jcef5lh/records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Keep this as application/json
          'xc-token': '5JbAYLnScNa5MPqax8t0zLRnPWVoSacZ346Xi9_F',
        },
        body: JSON.stringify({
          "Email": formData.email,
          "Full Name": formData.fullname,
          "Title": formData.title,
          "Topic Category": formData.category,
          "What would you like to Learn?": formData.description,
          "Subscribe to NadiAI Hub Newsletter": formData.subscribe,
        }),
      });

      if (response.ok) {
        setMessage('Thank you! Your content request has been submitted successfully.');
        // Reset form fields
        setFormData({
          email: '',
          fullname: '',
          title: '',
          category: 'AI for VC',
          description: '',
          subscribe: true,
        });
        setTimeout(() => {
          window.location.href = '/'; // Redirect to home
        }, 2000); // 2-second delay
      } else {
        setMessage('There was an error submitting your form. Please try again.');
      }
    } catch (error) {
      setMessage('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <div className='page-details'>
              <Heading content="Suggest New Content" />
              <h3>Let us know your interest to help us create valuable resources for you.</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="login-wrap">
                <div className="login-html">
                  <div className="login-form">
                    <div className="sign-in-htm">
                      <div className="group">
                        <label htmlFor="fullname" className="label">Full Name</label>
                        <input id="fullname" type="text" className="input" value={formData.fullname} onChange={handleChange} />
                        {errors.fullname && <p style={{ color: 'red' }}>{errors.fullname}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="title" className="label">Title</label>
                        <input id="title" type="text" className="input" value={formData.title} onChange={handleChange} />
                        {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="email" className="label">Work Email</label>
                        <input id="email" type="email" className="input" value={formData.email} onChange={handleChange} />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </div>
                      <div className="group">
                        <label htmlFor="category" className="label">Topic Category</label>
                        <select id="category" className="input" value={formData.category} onChange={handleChange}>
                          <option value="AI for VC">AI for VC</option>
                          <option value="AI in Sales">AI in Sales</option>
                          <option value="AI in Marketing">AI in Marketing</option>
                          <option value="AI Basic">AI Basic</option>
                          <option value="AI for Executive">AI for Executive</option>
                          <option value="AI Guides">AI Guides</option>
                        </select>
                      </div>
                      <div className="group">
                        <label htmlFor="description" className="label">What would you like to Learn?</label>
                        <textarea id="description" className="input" value={formData.description} onChange={handleChange}></textarea>
                        {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>}
                      </div>
                      <div className="group">
                        <input id="subscribe" type="checkbox" className="check" checked={formData.subscribe} onChange={handleChange} />
                        <label htmlFor="subscribe"><span className="icon"></span> Subscribe to NadiAI Hub Newsletter</label>
                      </div>
                      <div className="group">
                        <input type="submit" className="button" value="Submit Request" />
                      </div>
                      {message && <p className='submitmessage'>{message}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ContentRequest;
