import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './disclaimer.style';

const Disclaimer = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/cryptoModern/illustration1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
        {/*<div className="image">
            <Fade up>
              <GatsbyImage
                src={
                  (Data.illustration !== null) | undefined
                    ? Data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Privacy Illustration"
              />
            </Fade>
          </div>*/}
          <div className="content">

            <Heading content="Disclaimer" />
            {/*<Text content="Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiu" />*/}
            <p class="small-font">
        Through the NadiAI Platform and Hub, we aim to provide a comprehensive Knowledge Center featuring articles, videos, audio files, and other resources designed to educate and inform users about various topics related to artificial intelligence and its applications. While we strive to ensure the accuracy and relevance of the content, we acknowledge that limitations exist due to the nature of the technology and the ever-evolving landscape of AI.</p>
        <p className='h2'>Key Limitations of Information Provided</p>
        <ol class="small-font">
          <li class="small-font"><b>Educational Purpose Only:</b> All materials, including articles, videos, audio files, and AI-generated responses, are provided for informational and educational purposes. They are not intended as professional advice or a substitute for consulting qualified experts in fields such as legal, medical, financial, or technical matters.</li>
          <li>
            <b>Generative AI Limitations:</b>
            <ul>
              <li>The AI-powered features of the NadiAI Platform may occasionally generate inaccurate, incomplete, or misleading responses due to the inherent challenges of advanced AI models. This phenomenon, commonly known as "hallucination," can result in outputs that appear credible but lack factual basis.</li>
              <li>NadiAI may not always reflect the most up-to-date information, particularly for recent developments or fast-changing industries.</li>
            </ul>
          </li>
          <li><b>User Responsibility:</b> Users are advised not to rely solely on any content or outputs provided through the Hub or NadiAI Platform for critical decision-making. Independent verification and consultation with qualified professionals are strongly recommended for matters of significant importance.</li>
        </ol>
        <p className='h2'>Feedback and Continuous Improvement</p>
        <p class="small-font">
        We are committed to improving the quality and accuracy of the NadiAI Hub and Platform. Your feedback is invaluable in this effort. If you encounter inaccuracies or content that does not meet your expectations, please inform us:
        </p>
        <ul>
          <li>Use the thumbs-down button for AI-generated responses.</li>
          <li>Share your feedback at <a href='support@nadiai.ai'>support@nadiai.ai.</a></li>
        </ul>
        <p class="small-font">
        By accessing the NadiAI Hub and Platform, you acknowledge these limitations and agree to use the resources and AI outputs responsibly. NadiAI, Inc. disclaims any liability for the misuse of the information or for decisions made solely based on the content provided through the platform.
        </p>
      
          </div>
          
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Disclaimer;
